// TypeEffect.tsx
import React, { useEffect } from 'react';
import Typed from 'typed.js';

interface TypeEffectProps {
  text: string;
  id: string;
  loop?: boolean;
}

const TypeEffect: React.FC<TypeEffectProps> = ({ text, id, loop = true }) => {
  useEffect(() => {
    const options = {
      strings: [text],
      typeSpeed: 50,
      backSpeed: 50,
      startDelay: -50, // Set a negative startDelay to remove the <span>
      showCursor: false, // Set showCursor to false to hide the cursor
      loop: loop,
    };

    const typed = new Typed(`.typed-text-${id}`, options);

    return () => {
      typed.destroy();
    };
  }, [text, id, loop]);

  return text ? (
    <p className={`md:text-[3vw] text-[#9DFFCA] font-bold py-2 px-3 typed-text-${id}`}></p>
  ) : null;
};

export default TypeEffect;
