import React from 'react';
import TypeEffect from '../components/TypeEffect';
import Videos from '../components/Videos';

interface Props {
    // Define your component props here
}

const Project: React.FC<Props> = (props) => {
    return (
        <>
            <div className='p-[5vw] bg-gradient-to-b from-[#180C24] to-black text-center'>
                <TypeEffect id='1' text='Explore My Portfolio Projects' loop={false} />
                <div className='mt-[5vw]'>
                    <hr className='mb-[2vw]' />
                    <p className='text-transparent bg-clip-text bg-gradient-to-r from-[#9DFFCA] to-white md:text-[2vw]'>
                        In the realm of Web Development, I've had the privilege of contributing to impactful projects. Explore the following links to journey through the websites where I collaborated with talented teams. My focus extended to crafting captivating animations that brought these digital experiences to life.
                    </p>
                    <br />
                    <p className='text-transparent bg-clip-text bg-gradient-to-r from-[#9DFFCA] to-white md:text-[2vw] text-start'>
                        I bring a distinctive touch to UI/UX development and animations, offering a repertoire of completed projects that showcase my creativity and expertise. Explore my portfolio to witness the seamless integration of captivating user interfaces, thoughtful user experiences, and dynamic animations. Let my work speak to the innovation and precision I bring to each project.</p>

                    <ul className=' list-disc my-5 shadow-lg shadow-white rounded-lg p-5 pl-10 box-border md:w-[60vw] mx-auto md:mt-16'>
                        <a target='_blank' rel='noreferrer noopener' href="https://opus.ai/">
                            <li className='md:text-[1.5vw] text-[#9DFFCA] md:pb-[3vw] font-extrabold hover:border-4 hover:border-r-0  border-[blue] rounded-lg duration-500'>
                                Opus AI
                                <div className='md:md:flex justify-between items-center'>
                                    <p className='text-start p-3 text-blue-500 md:text-[1vw]'><span className='text-[#9DFFCA]'>FrameWork:</span> Svelte <br /> <span className='text-[#9DFFCA]'>Responsible for:</span> Loading animation<br /> <span className='text-[#9DFFCA]'>Specific Section / Pages worked on:</span> I worked on LoadingPage Animation and after that words ChangeAnimation on landingpage and some other pages of this site for the Desktop and Mobile.</p>
                                    <Videos srcVideo='/videos/Opus-Home.mp4' />
                                </div>
                            </li>
                        </a>
                        <a target='_blank' rel='noreferrer noopener' href="https://pitchdeck.opus.ai/mission">
                            <li className='md:text-[1.5vw] md:pb-[3vw] text-[#9DFFCA] font-extrabold hover:border-4 hover:border-r-0  border-[blue] rounded-lg duration-500'>
                                PitchDeck
                                <div className="md:flex justify-between items-center">
                                    <p className='text-start p-3 text-blue-500 md:text-[1vw]'><span className='text-[#9DFFCA]'>FrameWork:</span> Svelte <br /> <span className='text-[#9DFFCA]'>Responsible for:</span> Loading animation<br /> <span className='text-[#9DFFCA]'>Specific Section / Pages worked on:</span> I worked on MissionPage, OutcomesPage, FuturePage, TechnologyPage, RoadmapPage website for Desktop and Mobile <br /> <span className='text-[#9DFFCA]'>Animation Complete By:</span> Custom Animations and all Transitions</p>
                                    <Videos srcVideo='/videos/-Mission.mp4' />
                                </div>
                            </li>
                        </a>
                        <a target='_blank' rel='noreferrer noopener' href="https://commoncoin.club/">
                            <li className='md:text-[1.5vw] md:pb-[3vw] text-[#9DFFCA] font-extrabold hover:border-4 hover:border-r-0  border-[blue] rounded-lg duration-500'>
                                Common Coin
                                <div className="md:flex items-center justify-between">
                                    <p className='text-start p-3 text-blue-500 md:text-[1vw]'><span className='text-[#9DFFCA]'>FrameWork:</span> Svelte <br /> <span className='text-[#9DFFCA]'>Responsible for:</span> Loading animation<br /> <span className='text-[#9DFFCA]'>Specific Section / Pages worked on:</span> I worked on Loading Animation for Desktop and Mobile <br /> <span className='text-[#9DFFCA]'>Animation Complete By:</span> Custom Animations and all Transitions</p>
                                    <Videos srcVideo='/videos/COMN-Home.mp4' />
                                </div>
                            </li>
                        </a>
                        <a target='_blank' rel='noreferrer noopener' href="https://parsecinstitute.org/">
                            <li className='md:text-[1.5vw] md:pb-[3vw] text-[#9DFFCA] font-extrabold hover:border-4 hover:border-r-0  border-[blue] rounded-lg duration-500'>
                                Parsec Institute
                                <div className="md:flex  items-center justify-between">
                                    <p className='text-start p-3 text-blue-500 md:text-[1vw]'><span className='text-[#9DFFCA]'>FrameWork:</span> Svelte <br /> <span className='text-[#9DFFCA]'>Responsible for:</span> Loading animation<br /> <span className='text-[#9DFFCA]'>Specific Section / Pages worked on:</span> I worked on entire website for Desktop and Mobile <br /> <span className='text-[#9DFFCA]'>Animation Complete By:</span> Custom Animations and all Transitions</p>
                                    <Videos srcVideo='/videos/Parsec-Institute-Home.mp4' />
                                </div>
                            </li>
                        </a>
                        <a target='_blank' rel='noreferrer noopener' href="https://draperuniversity.com/">
                            <li className='md:text-[1.5vw] md:pb-[3vw] text-[#9DFFCA] font-extrabold hover:border-4 hover:border-r-0  border-[blue] rounded-lg duration-500'>
                                Draper University
                                <div className="md:flex  items-center justify-between">
                                    <p className='text-start p-3 text-blue-500 md:text-[1vw]'><span className='text-[#9DFFCA]'>FrameWork:</span> Svelte <br /> <span className='text-[#9DFFCA]'>Responsible for:</span> Loading animation<br /> <span className='text-[#9DFFCA]'>Specific Section / Pages worked on:</span> I worked on entire website for Desktop and Mobile <br /> <span className='text-[#9DFFCA]'>Animation Complete By:</span> Custom Animations and all Transitions</p>
                                    <Videos srcVideo='/videos/Draper-Home.mp4' />
                                </div>
                            </li>
                        </a>
                        <a target='_blank' rel='noreferrer noopener' href="https://opus.ai/token">
                            <li className='md:text-[1.5vw] md:pb-[3vw] text-[#9DFFCA] font-extrabold hover:border-4 hover:border-r-0  border-[blue] rounded-lg duration-500'>
                                Token Sale Animation Opus
                                <div className="md:flex  items-center justify-between">
                                    <p className='text-start p-3 text-blue-500 md:text-[1vw]'><span className='text-[#9DFFCA]'>FrameWork:</span> Svelte <br /> <span className='text-[#9DFFCA]'>Responsible for:</span> Loading animation<br /> <span className='text-[#9DFFCA]'>Specific Section / Pages worked on:</span> I worked on this and complete the token page animation for Mobile and Desktop fully responsive <br /> <span className='text-[#9DFFCA]'>Animation Complete By:</span> Using GSAP animations to triger the animation on scroll</p>
                                    <Videos srcVideo='/videos/Opus-COMN.mp4' />
                                </div>
                            </li>
                        </a>
                    </ul>
                </div>
            </div>
        </>
    );
};

export default Project;