import React from 'react';
import TypeEffect from '../components/TypeEffect';

interface Props {
    // Define your component props here
}

const About: React.FC<Props> = (props) => {
    return (
        <>
            <div className='w-full p-5 text-center bg-repeat' style={{ backgroundImage: 'url("/images/background.png")' }}>
                <p className='text-white md:text-[2vw] font-extrabold'>HI, MY NAME IS</p>
                <TypeEffect id='1' text='MUHAMMAD ZAIN RIAZ ANSARI' loop={false} />
                <p className='text-white md:text-[2vw] font-extrabold'>I AM</p>
                <TypeEffect id='2' text='PROGRAMMER or DIGITAL SOLUTIONS EXPERT' loop={false} />
                <div className='bg-[#180c24a2] shadow-lg shadow-white backdrop-blur-sm md:w-[70vw] mx-auto p-5 rounded-xl text-start'>
                    <img src="/images/zain.jpg" alt="zain" className='md:w-[10vw] md:h-auto rounded-xl mx-auto mb-4 border-2 border-[#9DFFCA] ' />
                    <hr />
                    <p className='text-[#9DFFCA] md:text-[2vw] mt-2'>
                        OBJECTIVE
                    </p>
                    <p className='text-white md:text-[1.5vw]'>
                        Passionate and committed, I am a versatile professional with a skill set spanning <span className='text-[#9DFFCA]'>web development, animation, electrical engineering, and cybersecurity.</span> As a proficient web developer, I seamlessly blend creativity with technical acumen, ensuring an immersive user experience in every project. Drawing from my background as an <span className='text-[#9DFFCA]'>Associate Engineer in Electrical Engineering,</span> coupled with a specialized focus on cybersecurity, I bring a comprehensive and holistic approach to creative problem-solving. Eager to contribute groundbreaking solutions, I am unwaveringly dedicated to pushing the boundaries of technology and design. Let's collaborate and transform your visions into exceptional and <span className='text-[#9DFFCA]'>secure digital experiences.</span>                    <br />
                        In my pursuit of a <span className='text-[#9DFFCA]'>Front-end Engineer</span> role, I actively seek a position in a dynamic and stimulating environment. Driven to continually challenge and elevate my skills, my goal is to cultivate expertise in management, technical proficiency, and effective communication. I am wholeheartedly dedicated to achieving professional excellence across all facets of my work.                    </p>
                    <p className='text-[#9DFFCA] md:text-[2vw] mt-2'>
                        SERVICES
                    </p>
                    <p className='text-white md:text-[1.5vw]'>
                        I offer web development, animations, and social media management services. From creating dynamic websites to bringing interfaces to life with animations, and managing social media strategically, I ensure engaging digital experiences.
                    </p>

                    <div className='md:flex items-center justify-evenly w-full'>
                        <div className='relative hover:scale-125 cursor-pointer duration-500 hover:-translate-y-52 hover:bg-black p-5 w-fit mx-auto rounded-xl'>
                            <img src="/images/sm.png" alt="sm" className='w-[25vw] h-auto mx-auto' />
                            <p className='w-full text-[#9DFFCA] font-bold text-center md:text-[1vw]'>Social Media Management</p>
                        </div>
                        <div className='relative hover:scale-125 cursor-pointer duration-500 hover:-translate-y-52 hover:bg-black p-5 w-fit mx-auto rounded-xl'>
                            <img src="/images/webani.png" alt="sm" className='w-[25vw] h-auto mx-auto' />
                            <p className='w-full text-[#9DFFCA] font-bold text-center md:text-[1vw]'>Web Animations & Devlopemt</p>
                        </div>
                        <div className='relative hover:scale-125 cursor-pointer duration-500 hover:-translate-y-52 hover:bg-black p-5 w-fit mx-auto rounded-xl'>
                            <img src="/images/wd.png" alt="sm" className='w-[25vw] h-auto mx-auto' />
                            <p className='w-full text-[#9DFFCA] font-bold text-center md:text-[1vw]'>UI/UX Design Development</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default About;