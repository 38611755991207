import React from 'react';
import TypeEffect from '../components/TypeEffect';
import { TiHtml5, TiCss3 } from "react-icons/ti";
import { FaBootstrap } from "react-icons/fa6";
import { BiLogoTailwindCss } from "react-icons/bi";
import { TbBrandJavascript } from "react-icons/tb";
import { SiTypescript, SiSvelte, SiGitea, SiGitlab, SiCyberdefenders, SiFirewalla, SiLinux } from "react-icons/si";
import { RiReactjsLine } from "react-icons/ri";
import { IoLogoGithub, IoLogoWordpress } from "react-icons/io5";
import { FaNetworkWired } from "react-icons/fa6";



interface Props {
    // Define your component props here
}

const Resume
    : React.FC<Props> = (props) => {
        return (
            <>
                <div className='p-[5vw] bg-gradient-to-b from-[#180C24] to-black'>
                    <div className='md:flex text-center items-center justify-between'>
                        <TypeEffect id='1' text='MUHAMMAD ZAIN RIAZ ANSARI' />
                        <TypeEffect id='2' text='Digital Solutions Expert' />
                    </div>
                    <div className='md:w-[65vw] mx-auto p-[3vw] shadow-lg shadow-white rounded-md'>
                        <div className='mb-5 text-start'>
                            <p className='text-[#9DFFCA] font-bold md:text-[2vw]'>TECHNICAL EXPERIENCE</p>
                            <div className='mb-5'>
                                <p className='text-[#9DFFCA] font-bold md:text-[1vw]'>Front-End Engineer at Opus.ai (2021 - 2023)</p>
                                <p className='text-white md:text-[1vw]'>
                                    I designed and developed the Opus.ai website with CSS and GSAP animations, integrated a mobile app, and ensured production deployment. I also mentored junior developers and emphasized code quality and custom animations for a seamless user experience.
                                </p>
                            </div>
                            <div className='mb-5'>
                                <p className='text-[#9DFFCA] font-bold md:text-[1vw]'>Front-End Developer at Ennovention.uk (2019-2020)</p>
                                <p className='text-white md:text-[1vw]'>
                                    I oversaw the creation of responsive, custom WordPress web interfaces, working closely with cross-functional teams to deliver high-quality, tailored solutions. I managed project timelines, budgets, and resources, accommodating unique customizations. I implemented WordPress best practices for efficient and visually appealing websites that met client and user needs.
                                </p>
                            </div>
                            <div className='mb-5'>
                                <p className='text-[#9DFFCA] font-bold md:text-[1vw]'>Linux Administrator & Cloud Computing Specialist CyberBuggs.com (2018-2019)</p>
                                <p className='text-white md:text-[1vw]'>
                                    I designed and developed responsive WordPress web interfaces for optimal user experiences. Collaborated with cross-functional teams to deliver tailor-made solutions, managed projects, and applied best practices for efficient and visually appealing websites.
                                </p>
                            </div>
                        </div>
                        <div className='mb-5 text-start'>
                            <p className='text-[#9DFFCA] font-bold md:text-[2vw]'>OTHER EXPERIENCE</p>
                            <div className='mb-5'>
                                <p className='text-[#9DFFCA] font-bold md:text-[1vw]'>Salesman at Alixeeshan Studio (2012-2013) </p>
                                <p className='text-white md:text-[1vw]'>
                                    With one year of experience, I successfully contributed to the sales team at Alixeeshan Studio, demonstrating strong customer service and communication skills while assisting customers with their needs.
                                </p>
                            </div>
                            <div className='mb-5'>
                                <p className='text-[#9DFFCA] font-bold md:text-[1vw]'>Assistant Manager at HushPuppies (2015-2016)</p>
                                <p className='text-white md:text-[1vw]'>
                                    During my one-year tenure, I efficiently fulfilled the role of Assistant Manager at HushPuppies, gaining valuable experience in team leadership, store operations, and customer satisfaction, all of which contributed to the store's success.
                                </p>
                            </div>
                            <div className='mb-5'>
                                <p className='text-[#9DFFCA] font-bold md:text-[1vw]'>Store In-charge at PEL, Lahore (6 Months)</p>
                                <p className='text-white md:text-[1vw]'>
                                    As the Store In-charge at PEL, Lahore, I managed day-to-day operations and maintained inventory, ensuring the smooth functioning of the store during my six-month tenure.                            </p>
                            </div>
                        </div>
                        <div className='mb-5 text-start'>
                            <p className='text-[#9DFFCA] font-bold md:text-[2vw]'>CERTIFICATIONS</p>
                            <div className='md:flex justify-evenly items-end'>
                                <div>
                                    <img src="/images/CloudComputing.png" alt="cloudcomputing" className='md:w-[20vw] h-auto border-4 border-[#9DFFCA] rounded-xl hover:scale-150 cursor-pointer duration-500' />
                                    <p className='text-white md:text-[1.5vw]'>Cloud Computing</p>
                                </div>
                                <div>
                                    <img src="/images/Security.png" alt="Security" className='md:w-[20vw] h-auto border-4 border-[#9DFFCA] rounded-xl hover:scale-150 cursor-pointer duration-500' />
                                    <p className='text-white md:text-[1.5vw]'>Network Security</p>
                                </div>
                            </div>
                            <div className='md:flex justify-evenly items-end'>
                                <div>
                                    <img src="/images/mircosoftcert.png" alt="cloudcomputing" className='md:w-[20vw] h-auto border-4 border-[#9DFFCA] rounded-xl hover:scale-150 cursor-pointer duration-500' />
                                    <p className='text-white md:text-[1.5vw]'>Fundamentals & Configrations</p>
                                </div>
                                <div>
                                    <img src="/images/cyberSecurity.jpeg" alt="Security" className='md:w-[20vw] h-auto border-4 border-[#9DFFCA] rounded-xl hover:scale-150 cursor-pointer duration-500' />
                                    <p className='text-white md:text-[1.5vw]'>Cyber Security</p>
                                </div>
                            </div>
                        </div>
                        <div className='mb-5 text-start'>
                            <p className='text-[#9DFFCA] font-bold md:text-[2vw]'>EDUCATION</p>
                            <ul className='mb-5 list-disc pl-6'>
                                <li className='text-white md:text-[1.5vw]'>A.D.P.CS(Assosiate Degree Program ComputerScience) from GCUF (2019-2021)</li>
                                <li className='text-white md:text-[1.5vw]'>D.A.E(Diploma of Assosiate Engineer) from TEVTA Lahore (2016-2019)</li>
                                <li className='text-white md:text-[1.5vw]'>Matriculation from Baise Lahore (2013-2015)</li>
                            </ul>
                        </div>
                        <div className='mb-5 text-start'>
                            <p className='text-[#9DFFCA] font-bold md:text-[2vw]'>SKILLS</p>
                            <div className='flex md:gap-5 items-center w-full'>
                                <TiHtml5 size={60} color='blue' title='Hyper Text Markup Language' className='hover:scale-150 cursor-pointer duration-500' />
                                <TiCss3 size={60} color='blue' title='Case Cading Style Sheet' className='hover:scale-150 cursor-pointer duration-500' />
                                <FaBootstrap size={60} color='blue' title='Bootstrap' className='hover:scale-150 cursor-pointer duration-500' />
                                <BiLogoTailwindCss size={60} color='blue' title='Tailwind CSS' className='hover:scale-150 cursor-pointer duration-500' />
                                <TbBrandJavascript size={60} color='blue' title='Java Script' className='hover:scale-150 cursor-pointer duration-500' />
                                <SiTypescript size={60} color='blue' title='Type Script' className='hover:scale-150 cursor-pointer duration-500' />
                                <RiReactjsLine size={60} color='blue' title='ReactJS' className='hover:scale-150 cursor-pointer duration-500' />
                                <SiSvelte size={60} color='blue' title='SvelteJS' className='hover:scale-150 cursor-pointer duration-500' />
                                <IoLogoGithub size={60} color='blue' title='GitHub' className='hover:scale-150 cursor-pointer duration-500' />
                                <SiGitea size={60} color='blue' title='Gitea' className='hover:scale-150 cursor-pointer duration-500' />
                                <SiGitlab size={60} color='blue' title='GitLab' className='hover:scale-150 cursor-pointer duration-500' />
                                <IoLogoWordpress size={60} color='blue' title='Wordpress' className='hover:scale-150 cursor-pointer duration-500' />
                                <FaNetworkWired size={60} color='blue' title='Networking' className='hover:scale-150 cursor-pointer duration-500' />
                                <SiCyberdefenders size={60} color='blue' title='Cyber Security' className='hover:scale-150 cursor-pointer duration-500' />
                                <SiFirewalla size={60} color='blue' title='FireWall' className='hover:scale-150 cursor-pointer duration-500' />
                                <SiLinux size={60} color='blue' title='Linux Administrator' className='hover:scale-150 cursor-pointer duration-500' />
                            </div>
                        </div>
                        <div className='mb-5 text-start'>
                            <p className='text-[#9DFFCA] font-bold md:text-[2vw]'>OTHER SKILLS</p>
                            <ul className='mb-5 list-disc pl-6'>
                                <li className='text-white md:text-[1.5vw]'>Project Management</li>
                                <li className='text-white md:text-[1.5vw]'>Strong Decision-Maker</li>
                                <li className='text-white md:text-[1.5vw]'>Creative Design</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </>
        );
    };

export default Resume;