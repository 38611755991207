import React from 'react';
import LogoAnimation from '../components/LogoAnimation';
import TypeEffect from '../components/TypeEffect';

interface Props {
    // Define your component props here
}

const Home: React.FC<Props> = (props) => {
    return (
        <>
            <div className='w-full p-5 bg-cover' style={{ backgroundImage: 'url("/images/background.png")' }}>
                <div className='cursor-pointer md:flex md:items-center shadow-md shadow-gray-500 backdrop-blur-md w-fit gap-[5vw] h-auto p-5 rounded-3xl mx-auto box-border relative z-10'>
                    <img src="/images/zain.jpg" alt="zain" className='md:w-[10vw] h-auto rounded-3xl shadow-lg shadow-gray-500' />
                    <LogoAnimation logoHeight='auto' logoWidth='md:15vw sm:10vw' />
                </div>
                <div className='backdrop-blur-md shadow-md shadow-gray-500 textRotate px-10 rounded-2xl rounded-tr-none rounded-tl-none relative w-fit mx-auto'>
                    <p className='text-[#9DFFCA] md:text-[2vw] font-semibold'>FrontEnd</p>
                    <ul className=' list-disc px-5'>
                        <li className='text-[#9DFFCA] md:text-[1vw] font-semibold'>Engineer</li>
                        <li className='text-[#9DFFCA] md:text-[1vw] font-semibold'>Animator</li>
                        <li className='text-[#9DFFCA] md:text-[1vw] font-semibold'>Developer UI/UX</li>
                    </ul>
                </div>
            </div>
            <p className='text-white text-center my-[2vw] font-extrabold uppercase md:text-[2vw]'>Explore The New Ideas With me I can Change it Into real things</p>
            <div className='text-center'>
                <div className='relative'>
                    <img src="/images/dev2.jpg" alt="developer" className=' md:w-[40vw] h-auto' />
                    <div className="md:absolute top-0 right-0 p-5 -z-10 md:w-[60vw] h-full bg-gradient-to-r from-[#338CA4] to-black flex justify-center items-center">
                        <TypeEffect id='1' text='Web Engineering' />
                    </div>
                </div>
                <div className='relative'>
                    <img src="/images/work2.gif" alt="developer" className=' md:w-[40vw] h-auto bg-[cadetblue]' />
                    <div className="md:absolute top-0 right-0 p-5 -z-10 md:w-[60vw] h-full bg-gradient-to-r from-[#0E032D] to-black flex justify-center items-center">
                        <TypeEffect id='2' text='Web Animations' />

                    </div>
                </div>
                <div className='relative'>
                    <img src="/images/dev.webp" alt="developer" className=' md:w-[40vw] h-auto bg-[cadetblue]' />
                    <div className="md:absolute top-0 right-0 p-5 -z-10 md:w-[60vw] h-full bg-gradient-to-r from-[#5F9EA0] to-black flex justify-center items-center">
                        <TypeEffect id='3' text='UI / UX Web Development' />
                    </div>
                </div>
            </div>

            <a href="/contact" className='text-white bg-red-500 md:text-[2vw] flex justify-center p-5 hover:font-extrabold duration-700 hover:bg-white hover:text-red-500'>Contact Me </a>
        </>
    );
};

export default Home;
