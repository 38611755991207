import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Home from './pages/Home';
import './App.css';
import MenuBar from './components/MenuBar';
import Contact from './pages/Contact';
import Resume from './pages/Resume';
import About from './pages/About';
import Project from './pages/Project';
import Glitch from './components/Glitch';
import ContactComp from './components/contactComp';
import CustomCursor from './components/Cursor';

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 4000);

    return () => clearTimeout(timer);
  }, []);

  const isSmallScreen = () => window.innerWidth <= 768;

  return (
    <>
      {loading ? (
        <Glitch />
      ) : (
        <Router>
          {!isSmallScreen() && <CustomCursor />}
          <ContactComp />
          <MenuBar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/resume" element={<Resume />} />
            <Route path="/about" element={<About />} />
            <Route path="/projects" element={<Project />} />
          </Routes>
        </Router>
      )}
    </>
  );
}

export default App;
