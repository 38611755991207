import React from 'react';
import { IoCall, IoLogoLinkedin, IoLogoGithub } from 'react-icons/io5';
import { IoLogoWhatsapp } from 'react-icons/io';
import { BsEnvelopeAtFill } from "react-icons/bs";


interface Props {
    // Define your component props here
}

const Contact: React.FC<Props> = (props) => {
    return (
        <>
            <div className='w-full p-5 text-center' style={{ backgroundImage: 'url("/images/background.png")' }}>
                <p className='text-white md:text-[2vw] font-extrabold'>Contact me</p>
                <p className='text-white md:text-[1.3vw] '>Engineered solutions to redefine your digital footprint.</p>
                <p className='text-red-500 md:text-[1vw] '>So Ping me Back</p>
            </div>
            <div className='bg-gradient-to-r from-[#180C24] to-black relative'>
                <div className='text-center py-[2vw] px-[4vw] backdrop-blur-lg'>
                    <a href='tel:+971568150302' className='text-[#9DFFCA] hover:text-red-500 md:text-[2vw] flex items-center my-5 w-full gap-[5vw] px-5 font-bold hover:bg-white hover:rounded-lg duration-700'>
                        <IoCall color='red' size={30} />
                        Call
                        +971568150302
                    </a>
                    <a href='https://wa.me/923317105567' target="_blank" rel="noreferrer" className='text-[#9DFFCA] hover:text-red-500 md:text-[2vw] flex items-center my-5 w-full gap-[5vw] font-bold px-5 hover:bg-white hover:rounded-lg duration-700'>
                        <IoLogoWhatsapp color='red' size={30} />
                        WhatsApp Contact
                        +923317105567
                    </a>
                    <a href='https://www.linkedin.com/in/muhammadZain887/' target="_blank" rel="noreferrer" className='text-[#9DFFCA] hover:text-red-500 md:text-[2vw] flex items-center my-5 w-full gap-[5vw] font-bold px-5 hover:bg-white hover:rounded-lg duration-700'>
                        <IoLogoLinkedin color='red' size={30} />
                        Linkedin Profile
                    </a>
                    <a href='mailto:zainansari887@gmail.com' className='text-[#9DFFCA] hover:text-red-500 md:text-[2vw] flex items-center my-5 w-full gap-[5vw] font-bold px-5 hover:bg-white hover:rounded-lg duration-700'>
                        <BsEnvelopeAtFill color='red' size={30} />
                        Email to zainansari887@gmail.com
                    </a>
                    <a href='https://github.com/Zain887' target="_blank" rel="noreferrer" className='text-[#9DFFCA] hover:text-red-500 md:text-[2vw] flex items-center my-5 w-full gap-[5vw] font-bold px-5 hover:bg-white hover:rounded-lg duration-700'>
                        <IoLogoGithub color='red' size={30} />
                        GitHub Account
                    </a>
                </div>
                <img src="/images/contact2.gif" alt="contact" className='md:w-full h-auto p-[4vw] py-0 rounded-2xl overflow-hidden' />
            </div>
        </>
    );
};

export default Contact;