import React from 'react';

interface Video {
  srcVideo: string;
}

const Videos: React.FC<Video> = ({srcVideo}) => {
  return (

        <video
          loop
          muted
          autoPlay
          className="w-96 h-auto shadow-xl shadow-[#9DFFCA] hover:scale-150 duration-700 cursor-pointer"
        >
          <source src={srcVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      
  );
};

export default Videos;
