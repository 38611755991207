import React from 'react';
import { Link, useLocation } from 'react-router-dom';

interface Props {
  // Define your component props here
}

const MenuBar: React.FC<Props> = () => {
  const location = useLocation();

  const menuItems = [
    { label: 'Home', path: '/' },
    { label: 'About', path: '/about' },
    { label: 'Projects', path: '/projects' },
    { label: 'Resume', path: '/resume' },
    { label: 'Contact Me', path: '/contact' },
  ];

  return (
    <ul className='flex justify-evenly py-5 bg-[#16151A]'>
      {menuItems.map((menuItem, index) => (
        <li
          key={index}
          className={`duration-500 text-white md:px-4 md:text-[1vw] cursor-pointer ${
            location.pathname === menuItem.path ? 'bg-red-500 rounded-sm font-bold' : 'hover:bg-[#1a4d31]'
          }`}
        >
          <Link to={menuItem.path}>{menuItem.label}</Link>
        </li>
      ))}
    </ul>
  );
};

export default MenuBar;
