import React, { useEffect, useState } from 'react';
import './cursor.css';

const CustomCursor: React.FC = () => {
    const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

    const handleMouseMove = (e: MouseEvent) => {
        setCursorPosition({ x: e.pageX, y: e.pageY });
    };

    useEffect(() => {
        document.addEventListener('mousemove', handleMouseMove as any);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove as any);
        };
    }, []);

    return (
        <>
            <div className="neon-cursor" style={{ left: cursorPosition.x - 10, top: cursorPosition.y - 10 }}>
                <div className="neon-glow">
                </div>
                    <svg viewBox="0 0 62 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M59.3507 26.9042C61.6228 27.9925 61.6228 31.2274 59.3507 32.3156L4.66482 58.5068C2.38995 59.5963 -0.132294 57.5627 0.449997 55.1085L6.33568 30.302C6.44373 29.8466 6.44373 29.3722 6.33567 28.9168L0.450058 4.11141C-0.132248 1.65721 2.39 -0.376401 4.66488 0.713127L59.3507 26.9042Z" fill="#F1993F" />
                    </svg>
            </div>
        </>
    );
};

export default CustomCursor;
